/* eslint-disable */
import React from 'react'

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from '../../../node_modules/react-bootstrap'

import useLatestPosts from "../../hooks/useLatestBlogPost"
import Image from 'gatsby-image'

import './styled.css'
import { Link } from 'gatsby'
const FromTheBlog = (data) => {
  const latestPosts = useLatestPosts()

    return (
      <div className="homeblog-list">
        <Container>
          <Row>
            <Col md="5">
              <h3>Latest News</h3>
            </Col>
            <Col md="6">
            <ul>
              { latestPosts.map(card => {
                
                return (
                  <li>
                      <Link to={`/read/${card.node.slug}`}>
                        <h4>
                          {card.node.pageTitle}

                          <svg
                            className="styled__Arrow-d87459-0 bQLhSh"
                            width="14"
                            height="10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g stroke="#030303" fill="none" fillRule="evenodd">
                              <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                              <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                            </g>
                          </svg>
                        </h4>
                        {/* 
                        <div className="d-flex flex-wrap align-items-center justify-content-start">
                          <div className="author-img">
                            <Image fluid={card.node.authorInformation.iconImageAuthor.fluid} />
                          </div>
                          <span>{card.node.authorInformation.nameAuthor} . {card.node.createdDate}</span>
                        </div>
                        */}
                      </Link>
                  </li>
                )
              })}
            </ul>
            </Col>
          </Row>
          <Row className="text-center spt-5">
            <Col md="12">
              <Link
                className="fbutton styled__Link-d87459-1 fYDdlr colorblack"
                to="/read/news"
              >
                More news this way
                <svg
                  className="styled__Arrow-d87459-0 bQLhSh"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

export default FromTheBlog
