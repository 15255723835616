/* eslint-disable */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import './styled.css'
import Carousel from 'react-bootstrap/Carousel'

const Casestudiescarousel = () => {
    const carouselImage = useStaticQuery(graphql`
      query CarouselImage {
        vodacom: file(absolutePath: {regex: "/home/ProjectReadyThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1602) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        spwclienthandbook: file(absolutePath: {regex: "/home/SPWHandbookThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1602) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        standardbankdirect: file(absolutePath: {regex: "/home/StandardBankThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1602) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
  
    return (
      <div className="Casestudiescarousel spb-10">
        <Container>
          <Row className="sp-5 title-m-row">
            <Col className="col-sm-6 col-lg-4">
              <h3>
                Select projects we are currently working on...
              </h3>
            </Col>
          </Row>
          <Row>
            <Carousel interval={7000}>
              <Carousel.Item>
                <Row className="align-items-center">
                  <Col sm="7">
                    <Image alt="Getting Children Back to School With 'Project Ready" sizes={carouselImage.vodacom.childImageSharp.sizes}/>
                  </Col>
                  <Col sm="5">
                    <div class="type">Technology For Impact</div>
                    <h3>Getting Children Back to School With 'Project Ready'</h3>
                    <p>As part of <span class="yellowBackground">Vodacom’s Smart SO Partnership with Urbian</span>, Accenture, Deloitte and Letsema we are working hard to harness how digital can be used to accelerate the opening of schools in South Africa by providing PPE and food.</p>
                    {/*
                    <Link
                      className="styled__Link-lfEBUk iUBhrC font-weight-normal"
                      to="/casestudy"
                    >
                      View case study
                      <svg
                        className="styled__Arrow-fdeFVt fRPwvC"
                        width="14"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#030303" fill="none" fillRule="evenodd">
                          <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                          <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                        </g>
                      </svg>
                    </Link>
                    */}
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="align-items-center">
                  <Col sm="7">
                    <Image alt="Standard Bank Direct Life Insurance" sizes={carouselImage.standardbankdirect.childImageSharp.sizes}/>
                  </Col>
                  <Col sm="5">
                    <a href="/services/digitaltransformation/" class="type">Digital Transformation</a>
                    <h3>Standard Bank Direct Life Insurance</h3>
                    <p>Ongoing transformation of the Standard Bank Direct Life Insurance digital self-service customer-facing and employee-facing products, all enabled by us launching their first ever <span class="yellowBackground">insurance API.</span></p>
                    {/*
                    <Link
                      className="styled__Link-lfEBUk iUBhrC font-weight-normal"
                      to="/casestudy"
                    >
                      View case study
                      <svg
                        className="styled__Arrow-fdeFVt fRPwvC"
                        width="14"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#030303" fill="none" fillRule="evenodd">
                          <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                          <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                        </g>
                      </svg>
                    </Link>
                    */}
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="align-items-center">
                  <Col sm="7">
                    <Image alt="Sanlam Private Wealth Client-Service Handbook" sizes={carouselImage.spwclienthandbook.childImageSharp.sizes}
                    />
                  </Col>
                  <Col sm="5">
                    <a href="/services/companyhandbooks" class="type">Company Handbooks</a>
                    <h3>Sanlam Private Wealth Client-Service Handbook</h3>
                    <p>We are partnering with Sanlam Private Wealth to deliver a <span class="yellowBackground">Handbook</span> to over 300 portfolio managers. The aim is to have a central source of truth enabling portfolio managers to provide a consistent world-class client service experience to it's high-net-worth clients.</p>

                    {/*
                    <Link
                      className="styled__Link-lfEBUk iUBhrC font-weight-normal"
                      to="/casestudy"
                    >
                      View case study
                      <svg
                        className="styled__Arrow-fdeFVt fRPwvC"
                        width="14"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#030303" fill="none" fillRule="evenodd">
                          <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                          <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                        </g>
                      </svg>
                    </Link>
                    */}
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
            <Col md="12" className="text-center pt-5 mt-5">
              <Link
                className="styled__Link-lfEBUk iUBhrC font-weight-normal mt-5"
                to="/work/"
              >
                More case studies
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

export default Casestudiescarousel