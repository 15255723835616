/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styled.css'
import Carousel from 'react-bootstrap/Carousel'
class Testimonials extends React.Component {
  render() {
    return (
      <div className="testimonial Testimonials-slider">
        <div class="container">
          <Carousel interval={7000}>
            <Carousel.Item>
              <div className="w_inner">
                <div className="inner">
                  <h2>
                    Urbian's out-the-box thinking continues to help us reach a saturated fatigued market by creating a platform that customers truly want to be part of.
                  </h2>
                  <span className="attribution">Keith Elliott, Founder and CEO of Met-S Care</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="w_inner">
                <div className="inner">
                  <h2>
                    Urbian bring a sense of genius and of Machiavellian change in terms of driving everything that we do.
                  </h2>
                  <span className="attribution">Sinethemba Mafanya, GM Diageo Empowerment Trust SA</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="w_inner">
                <div className="inner">
                  <h2>
                    This is the first digital transformation project (and I’ve seen many) in years that I think might actually work.
                  </h2>
                  <span className="attribution">Nathan Van Rooyen, Head of Digital, Santam</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="w_inner">
                <div className="inner">
                  <h2>
                    The secret to their success is their ability to assemble talented teams of people that care. I think of Urbian as a critical member of my team, not some outsourced function.
                  </h2>
                  <span className="attribution">Kay Nash, CEO Distell Premium Wines</span>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    )
  }
}

export default Testimonials
