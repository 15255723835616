/* eslint-disable */
import React from 'react'

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from '../../../node_modules/react-bootstrap'

import './styled.css'
import { Link } from 'gatsby'
class Homecovid extends React.Component {
  render() {
    return (
      <div className="spb-8 Homecovid-min">
        <Container>
          <Row className="sp-5">
            <Col md="4">
              <h2>A partner in these challenging times</h2>
            </Col>
            <Col md="6" className="offset-md-1">
              <p>The biggest business challenge most of us will ever face, also provides us the opportunity to reset our priorities. Now is the moment to accelerate digital, harness it and shape a different future.</p>
            </Col>
          </Row>
          <Row>
            <Col sm="6 "md="3">
              <h4>Digital Transformation.</h4>
              <p>Ten years of real transformation just happened in a matter of weeks, as businesses everywhere adapt to a rapidly changing situation.</p>
              <Link
                className="styled__Link-lfEBUk iUBhrC"
                to="/services/digitaltransformation/"
              >
                Find out how we can help
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
            <Col sm="6 "md="3">
              <h4>Unlock New Revenue.</h4>
              <p>Looking to launch a consumer facing product or new service aimed at businesses? We can help. Enabling you to get a new breakthrough product in your customers’ hands within 100 days.</p>
              <Link
                className="styled__Link-lfEBUk iUBhrC"
                to="/services/digitalproductinnovation"
              >
                We can help you innovate
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
            <Col sm="6 "md="3">
              <h4>Remote Work Advisory.</h4>
              <p>Urbian has extensive experience building remote teams because we've been working remotely in-house for over a decade - it's part of our DNA - it's how we operate.</p>
              <Link
                className="styled__Link-lfEBUk iUBhrC"
                to="/services/remoteadvisory"
              >
                We help remote teams
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
            <Col sm="6 "md="3">
              <h4>Level-up Delivery.</h4>
              <p>We have built long-lasting capability inside companies through hiring, onboarding and coaching multi-disciplinary teams.</p>
              <Link
                className="styled__Link-lfEBUk iUBhrC"
                to="/services/newcapabilities"
              >
                We can help you deliver
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Homecovid
