/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'
// import { Theme } from '@theme'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import { Top, Services, Careers, Testimonials } from '../components/Home/'
import Gettouch from '../components/Gettouch'
import Homevideo from '../components/Homevideo'
import Homecovid from '../components/homecovid'
import Homecomplexproblems from '../components/Homecomplexproblems'
import Homeclientlogo from '../components/Homeclientlogo'
import Casestudiescarousel from '../components/Casestudiescarousel'
import FromTheBlog from '../components/FromTheBlog'

export interface IServicesContent {
  
}

interface IIndexPage {
  
}

const IndexPage: React.FC<IIndexPage> = () => {
  

  return (
    <div className="home-page">
      <Layout>
        <SEO />
        <Top  />
        <Homevideo></Homevideo>
        <Homecovid></Homecovid>
        <Testimonials></Testimonials>
        <Homecomplexproblems></Homecomplexproblems>
        <Homeclientlogo></Homeclientlogo>
        <Casestudiescarousel></Casestudiescarousel>
        <Services  />
        <div className="fromblog">
          <FromTheBlog />
        </div>
        
        <Gettouch></Gettouch>
      </Layout>
    </div>
  )
}

export default IndexPage


/* <Careers></Careers> */

