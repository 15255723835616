/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { IServicesContent } from '../../../pages'
// import { Big, List, ArrowLink } from '../../UI/'
// import { textByLine } from '../../../utils/textByLine'
import { Link } from 'gatsby'

import './styles.css'
const Services: React.FC<IServicesContent> = ({
  title,
  text,
  columns,
  cta,
  link,
}) => (

  <div>
    <Container>
      <Row>
        <Col lg="5">
          <h2>
            We build digital
            <br /> products
          </h2>
        </Col>
        <Col lg="6">
          <p>
            We are one of the few agencies in the world able to take a product idea end-to-end. From the first discovery meeting to a real shipped product. Whether you're needing a long-term <a href="/services/digitaltransformation/" className="yellowBackground">digital transformation partner</a> or have a great <a href="/services/digitalproductinnovation/" className="yellowBackground">new product</a> idea and nobody to start building it? <a href="/contact/" className="yellowBackground">We're your team</a>.
          </p>
        </Col>
      </Row>
      <Row>
        <Col class="grid-serv offset-lg-1 col-lg-10">
          <div class="services-list-min row">
            <div class="service-item col-md-4">
              <strong>PM &amp; Strategy </strong>
              <ul>
                <li>Product strategy</li>
                <li>Brand strategy</li>
                <li>Business strategy</li>
                <li>Product management</li>
                <li>Post-launch capacity building</li>
              </ul>
            </div>
            <div class="service-item col-md-4">
              <strong>Product Design</strong>
              <ul>
                <li>UX research &amp; testing</li>
                <li>Rapid prototyping</li>
                <li>User experience</li>
                <li>User interface</li>
                <li>Brand development</li>
                <li>Content &amp; writing</li>
                <li>Illustration</li>
                <li>Motion &amp; Video</li>
              </ul>
            </div>
            <div class="service-item col-md-4">
              <strong>Development</strong>
              <ul>
                <li>Technical architecture</li>
                <li>Front-end engineering</li>
                <li>Web-App development</li>
                <li>iOS &amp; Android Mobile App Development</li>
                <li>API &amp; CMS Development</li>
                <li>Code auditing</li>
                <li>Manual & automated QA</li>
                <li>DevOps</li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="mt-5 pt-5 text-center pb-5 mb-5">
          <Link
            className="fbutton styled__Link-d87459-1 fYDdlr colorblack"
            to="/services/"
          >
            More on our services
            <svg
              className="styled__Arrow-d87459-0 bQLhSh"
              width="14"
              height="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke="#030303" fill="none" fillRule="evenodd">
                <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
              </g>
            </svg>
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Services
