/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'



const Homevideo = () => {
  const HomevideoTopImages = useStaticQuery(graphql`
      query HomevideoTopImages {
        top: file(absolutePath: {regex: "/assets/images/home/FoondaBanner.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2500) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="hometopimg">
        <Container className="position-relative">
          <Image className="img-fluid" sizes={HomevideoTopImages.top.childImageSharp.sizes} alt="We design, develop and ship digital products for transformational companies embracing the African economy of the future"/> 
        </Container>
      </div>
    )
  }
export default Homevideo
