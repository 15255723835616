import { graphql, useStaticQuery } from "gatsby"

const useLatestPosts = () => {
  const { allContentfulBlogPost } = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(limit:3,filter: {categories: {eq: "News"}},sort: {order: DESC, fields: createdDate}) {
            edges {
              node {
                pageTitle
                slug
                createdDate(formatString: "MMMM DD, YYYY")
                authorInformation {
                  nameAuthor
                  biography {
                    childMarkdownRemark {
                      html
                    }
                  }
                  positionAuthor
                  iconImageAuthor {
                    id
                    fluid(maxWidth: 300){
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                }
                cardBlogListing {
                  id
                  titleCard
                  imageCard {
                    fluid(maxWidth: 800){
                      ...GatsbyContentfulFluid_withWebp
                    }
                    id
                  }
                  descriptionCard {
                    descriptionCard
                    childMarkdownRemark {
                      id
                    }
                  }
                }
                categories
              }
            }
          }
      }
    `
  )
  return allContentfulBlogPost?.edges
}
export default useLatestPosts